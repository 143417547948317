<template>
    <metainfo />
  <div id="app">
    <router-view></router-view>
    <Footer></Footer>
  </div>

</template>

<script>

import { useMeta } from 'vue-meta';
import Footer from './components/AppFooter.vue';

export default {

  
  name: 'App',
  components: {
    Footer,
  },
  data: () => ({
    //
  }),
}
</script>

<style>
 
.background {
  height: 100vh;
  width: 100vw;
  background-color: #121212;
}

.otherColor {
  background-color: #d4d4d4; 
}
</style>
