<template>
  <div class="scrollableRow" v-if="list">
    <h2 v-if="title">{{ title }}</h2> <!-- 타이틀이 존재할 때만 표시 -->

    <div
      v-if="!this.isMobile && this.showArrows"
      class="scrollableRow--arrowleft"
      @click="handleLeftArrow"
    >
    <span class="material-icons md-36">arrow_back_ios</span>
    </div>
    <div
    v-if="!this.isMobile && this.showArrows"
    class="scrollableRow--arrowright"
      @click="handleRightArrow"
    >
    <span class="material-icons md-36">arrow_forward_ios</span>

    </div>

    <div class="scrollableRow--listarea" 
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
    >
      <div class="scrollableRow--list" :style="[listStyle]">
        <div class="scrollableRow--item" v-for="item in list" :key="item.id">
          <slot v-bind:item="item"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
      required: true
    },
    list: {
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      scrollX: 0,
      startX: 0,
      isMobile: false,
      showArrows: false
    };
  },
  computed: {
    listStyle() {
      return {
        marginLeft: this.scrollX + "px",
        width: this.list.length * 218 + "px"
      };
    }
  },
  mounted() {
    this.isMobile = window.innerWidth <= 760;
    this.showArrows = this.list.length * 218 > window.innerWidth;
    console.log("this.showArrows >" + this.showArrows + " >" + this.list.length);
  },
  methods: {
    handleLeftArrow() {
      const userScreenSize = Math.round(window.innerWidth / 2);
      let roll = this.scrollX + userScreenSize;
      if (roll > 0) {
        roll = 0;
      }
      this.scrollX = roll;
    },
    handleRightArrow() {
      let listWidth = this.list.length * 218;
      const padding = 30;
      const userScreenSize = Math.round(window.innerWidth / 2);
      const remainingSpace = window.innerWidth - listWidth;

      if(remainingSpace < 0){
        let roll = this.scrollX - userScreenSize;
        if (remainingSpace > roll) {
          roll = remainingSpace - padding * 2;
        }
        this.scrollX = roll;
      }
     
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX - this.scrollX;
    },
    handleTouchMove(event) {
     const touch = event.touches[0];
    const touchX = touch.clientX - this.startX;
    const sensitivity = 0.5; // 스크롤 감도를 조절하는 계수 (0.5는 기본값)
    this.scrollX = Math.min(0, Math.max(-(this.list.length * 218 - window.innerWidth), touchX * sensitivity));
  }
  }
};
</script>

<style lang="scss">
.scrollableRow {
  position: relative;
  margin-bottom: 30px;
  color: white;

  h2 {
    margin: 0px 10px 0px 3%;
    color: white;
  }

  &--listarea::-webkit-scrollbar {
    display: none; /* WebKit (Chrome, Safari 등) 기반 브라우저에서 스크롤바를 숨깁니다. */
}

&--listarea {
    scrollbar-width: none; /* Firefox 기반 브라우저에서 스크롤바를 숨깁니다. */
    overflow-x: hidden;
    padding-left: 20px;
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

  &--list {
    transition: all ease 0.2s;
  }

  &--item {
    display: inline-block;
    width: 218px;
    height: 236px;
    cursor: pointer;
    margin-left:10px;
    margin-top:10px;
    img {
      width: 100%;
      height: 100%;
      transform: scale(1.0);
      transition: all ease 0.2s;
      -ms-interpolation-mode: bicubic;
    }
  }

  &--arrowleft,
  &--arrowright {
    position: absolute;
    width: 40px;
    height: 236px;
    background-color: rgba(0, 0, 0, 0.741);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    transition: all ease 0.5s;
    margin-top:10px;
  }

  &--arrowleft {
    left: 0;
  }
  &--arrowright {
    right: 0;
  }

  &:hover {
    .scrollableRow--arrowleft,
    .scrollableRow--arrowright {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    /* 모바일 화면 크기에 맞게 마진을 조정합니다. */
    &--listarea {
      padding-left: 0px; /* 화면 너비의 5%로 설정 */
    }
    
    &--list {
    transition: all ease 0.0s;
  }
  
    // &--arrowleft,
    // &--arrowright {
    //   visibility: hidden;
    // }
    
  }
}
</style>