<template>
  <footer>
    © 2024 Mecha-Info
  </footer>
</template>

<style lang="scss">
footer {
  margin-top: 6%;
  margin-bottom: 6%;
  text-align: center;
  color: white;
}
</style>
