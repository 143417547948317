<template>
  <header class="scrolled">
    <router-link to="/">
      <div class="header--logo">
        <img :src="require('../assets/logo_wh_img.png')" :alt="Mecha-Info" @click="clearSessionStorage"/>      
      </div>
    </router-link>

    <div class="header--center--logo">
      <img :src="require('../assets/logo_wh_letter.png')"  :alt="Mecha-Info" />
    </div>
  </header>
</template>


<script>
// import img from "./assets/logo_wh_img.png"

export default {
  props: {
    scrolled : {
      type : Boolean,
      default : true,
    }
  },
  methods: {
    clearSessionStorage() {
      // sessionStorage 삭제
      sessionStorage.clear();
      this.$emit('refresh');
    }
  }
};
</script>


<style lang="scss">
header {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background: transparent;
  transition: all ease 0.2s;
}

header.scrolled {
  background:rgba(18, 18, 18, 0.657);
}

.header--logo {
  height: 25px;
}

.header--center--logo img{
  height: 25px;
}


.header--logo img {
  height: 100%;
}

.header--user {
  border-radius: 3px;
  height: 35px;
}

.header--user img {
  height: 100%;
}
</style>
