import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { loadFonts } from './plugins/webfontloader'
import { i18n } from "./i18n/i18n"
import { createMetaManager } from 'vue-meta'

loadFonts()

const metaManager = createMetaManager({
  // 여기에 초기값 설정
  // 예를 들어, 기본 제목 설정
  title: 'My Website',
  // 기본 메타 태그 설정
  meta: [
    { vmid: 'description', name: 'description', content: 'My awesome website' },
    // 기타 메타 태그 설정 가능
  ],
});

createApp(App)
  .provide('i18n', i18n)
  .use(router)
  .use(i18n)
  .use(metaManager)
  .mount('#app')