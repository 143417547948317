<template>
  <div class="company-card" @click="handleClick">
    <img :src="imageUrl" class="image" :alt="title">
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: String,
    title: String
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>

<style scoped>
.company-card {
  cursor: pointer;
}

.image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease;
}

.title {
  margin-top: 5px;
  text-align: center;
  color:white;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow:hidden;
}

.image:active {
  transform: scale(1.1); /* 클릭되었을 때 이미지 크기를 확대합니다. */
}
</style>