 
// relevant methods
// import { doc, getDoc } from "firebase/firestore";
import { collection, query, where, getDoc, getDocs, doc } from "firebase/firestore"; 
import { db } from '../firebase/init.js'


export default {
   
   
  getCompanyInfo: async (englishName) => {
    
    console.log("api call getCompanyInfo");
    if (!englishName) return;

    var data = null;
    const q = query(collection(db, "company"), where("englishName", "==", englishName));
    const querySnap = await getDocs(q); 

    querySnap.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      data = doc.data();
    });
 
    return data;
  },

  getCompanyDatas: async () => {

    console.log("api call getCompanyDatas");
    let company = [];
    const querySnap = await getDocs(collection(db, 'company'));

      // add each doc to 'countries' array
      querySnap.forEach((doc) => {
        company.push(doc.data())
      })

      return company;
  },

  getSeriesDatas: async (id) => {

    console.log("api call getSeriesDatas");
    let series = [];
    const querySnap = await getDocs(collection(db, 'company', id, 'series'));

      // add each doc to 'countries' array
      querySnap.forEach((doc) => {
        series.push(doc.data())
      })

      return series;
  },

  getProductDatasByCompany: async (id) => {

    console.log("api call getProductDatasByCompany");
    let product = [];
    const q = query(collection(db, "product"), where("companyId", "==", id));
    const querySnap = await getDocs(q); 

      // add each doc to 'countries' array
      querySnap.forEach((doc) => {
        product.push(doc.data())
      })

      return product;
  },

  getProductInfo: async (productCode) => {
    
    console.log("api call getProductInfo");
    if (!productCode) return;

    var data = null;
    const q = query(collection(db, "product"), where("productCode", "==", productCode));
    const querySnap = await getDocs(q); 

    querySnap.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      data = doc.data();
    });
 
    return data;
  },

  getProductDatas: async () => {
    
    console.log("api call getProductDatas");

    let product = [];
    const querySnap = await getDocs(collection(db, 'product'));

      // add each doc to 'countries' array
      querySnap.forEach((doc) => {
        product.push(doc.data())
      })

      return product;
  },

  getContentDatas: async (id) => {

    console.log("api call getContentDatas");
    let series = [];
    const querySnap = await getDocs(collection(db, 'product', id, 'content'));

      // add each doc to 'countries' array
      querySnap.forEach((doc) => {
        series.push(doc.data())
      })

      return series;
  },

  getUpdateDate: async () => {

    const docRef = doc(db, "info", "web");
    const docSnap = await getDoc(docRef);
    console.log(">>>>" + docSnap.data()["updateDate"]);
      return docSnap.data()["updateDate"];
  },
};
