<template>
  <div id="home" style="color: #131313;">
    <app-header @refresh="handleRefresh" :scrolled="scrolled" />
    <featured-cover v-if="featuredCover" :item="featuredCover"/>
    <div class="big-menu">{{ $t('plamodel') }} </div>
    <section class="lists">
      <scrollable-row  v-if="productPlaNotList.length > 0"  :list="productPlaNotList"
      :title="$t('coming_soon') ">
        <template v-slot="props">
        
          <div class="image-container">
           
            <img
              v-if="props.item.imgUrl"
              :src="movieImage(props.item)"
              :alt="props.item.name"
              @click="handleProductClick(props.item.id)"
            >
         
          <div class="image-desc">
            <div v-if="isRecentUpdate(props.item.updateDate)" class="circle"></div> 
            <p class="image-title">{{ getImageTitle(props.item) }}</p>
            <p v-if="isVisibleSubtitle(props.item.productCode)" class="image-subtitle">{{ props.item.productCode }}</p>
          </div>
         
          </div>
        </template>
      </scrollable-row>
      <scrollable-row v-if="productPlaReleaseList.length > 0" :list="productPlaReleaseList"
      :title="$t('released') ">
        <template v-slot="props">
          <div class="image-container">
            <img
              v-if="props.item.imgUrl"
              :src="movieImage(props.item)"
              :alt="props.item.name"
              @click="handleProductClick(props.item.id)"
            >
          <div class="image-desc">
            <div v-if="isRecentUpdate(props.item.updateDate)" class="circle"></div>
            <p class="image-title">{{ getImageTitle(props.item) }}</p>
            <p v-if="isVisibleSubtitle(props.item.productCode)" class="image-subtitle">{{ props.item.productCode }}</p>
          </div>
         
          </div>
        </template>
      </scrollable-row>
    </section>
    <div class="big-menu" style="margin-top: 80px;">{{ $t('alloy_action_figure') }}</div>

    <scrollable-row v-if="productStealNotList.length > 0" :list="productStealNotList"
    :title="$t('coming_soon') ">
      <template v-slot="props">
          <div class="image-container">
            <img
              v-if="props.item.imgUrl"
              :src="movieImage(props.item)"
              :alt="props.item.name"
              @click="handleProductClick(props.item.id)"
            >
          <div class="image-desc">
            <div v-if="isRecentUpdate(props.item.updateDate)" class="circle"></div>
            <p class="image-title">{{ getImageTitle(props.item) }}</p>
            <p v-if="isVisibleSubtitle(props.item.productCode)" class="image-subtitle">{{ props.item.productCode }}</p>
          </div>
         
          </div>
        </template>
      </scrollable-row>

    <scrollable-row v-if="productStealReleaseList.length > 0" :list="productStealReleaseList"
    :title="$t('released') ">
      <template v-slot="props">
          <div class="image-container">
            <img
              v-if="props.item.imgUrl"
              :src="movieImage(props.item)"
              :alt="props.item.name"
              @click="handleProductClick(props.item.id)"
            >
          <div class="image-desc">
            <div v-if="isRecentUpdate(props.item.updateDate)" class="circle"></div>
            <p class="image-title">{{ getImageTitle(props.item) }}</p>
            <p v-if="isVisibleSubtitle(props.item.productCode)" class="image-subtitle">{{ props.item.productCode }}</p>
          </div>
         
          </div>
        </template>
      </scrollable-row>
    <!-- <loading v-if="moviesList.length <= 0"/> -->
    <!-- <div class="big-menu" style="margin-top: 80px;margin-bottom: 20px;">{{ $t('company_title') }}</div>
    
    <div class="image-grid">
    <CompanyCard 
      v-for="(item, index) in companyList" 
      :key="index" 
      :image-url="item.imgUrl" 
      :title=getCompanyName(item) 
      @click="handleCompanyClick(index)"
    />
  </div> -->
  <loading v-if="productReleaseList.length <= 0"/>

  </div>
 
</template>

<script>

import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import FeaturedCover from "../components/FeaturedCover";
import ScrollableRow from "../components/ScrollableRow";
import Loading from "../components/LoadingView";
import storeService from "../service/firestore.service";
import CompanyCard from "../components/CompanyCard.vue"; 
// import PhotoGrid from 'vue-photo-grid';
// import { inject } from 'vue';
// import { useMeta } from 'vue-meta';



export default {
  name: 'MechaInfo',
  components: {
    AppHeader,
    AppFooter,
    // FeaturedMovie,
    FeaturedCover,
    // MovieCard,
    ScrollableRow,
    Loading, 
    CompanyCard,
    // PhotoGrid
  },
  created() {
    this.init();
    console.log("home view created");
    this.loadPage();
  },
  mounted(){
    console.log("mounted");
    document.getElementById("home").addEventListener("scroll", this.scrollListener);

  },
  data: () => ({
    scrolled: false,
    moviesList: [],
    companyList : [],
    productList : [],
    productReleaseList : [],
    productPlaReleaseList : [],
    productPlaNotList : [],
    productStealReleaseList : [],
    productStealNotList : [],
    featuredCover: null,
    language : "en",
  }),

  methods: {
    
    init() {
      const userLang = (navigator.language || navigator.userLanguage || 'en').toUpperCase();  // 현재 브라우저에 설정된 언어값 확인 후 대문자로 변환
      if (userLang.indexOf('KO') > -1 || userLang.indexOf('KR') > -1) {  // 브라우저 언어가 한국어(KO-KR) 문자열에 'KO' 또는 'KR'이 포함된 경우
        this.language = 'ko';
        this.$i18n.locale = 'ko';
      }
      else {
        this.language = 'en';
        this.$i18n.locale = 'en';
      }
    },
    
    

    getAllProductNames(products) {
      return products.map(product => product.name).join(", ");
    },
    getAllCompanyNames(companies) {
      return companies.map(company => company.name);
    },
    getAllProductCodess(products) {
      return products.map(products => products.productCode);
    },
    isVisibleSubtitle(productCode){
      return !productCode.includes("NONE");
    }, 

    handleRefresh() {
      console.log("refesh!!");
      this.loadPage();
    },

    getImageTitle(item){
      if(this.language == "ko"){
        return item.name;
      }
      else{
        return item.englishName;
      }
    },

    getCompanyName(item){
      if(this.language == "ko"){
        return item.name;
      }
      else{
        return item.englishName;
      }
    },

    isRecentUpdate(updatedDate) {
      const currentTime = Date.now(); // 현재 시간(밀리초)
      const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000; // 3일을 밀리초로 환산한 값
      const diff = currentTime - updatedDate; // 현재 시간과 업데이트 시간의 차이(밀리초)
      return diff <= threeDaysInMillis; // 3일이 경과하지 않은 경우에만 true 반환
    },

    handleProductClick(productId) {
      // 여기에서 이미지를 클릭했을 때 처리할 로직을 작성합니다.
      let productCode = this.productList.find((element) => element.id == productId).productCode;

      this.$router.push({ path: `/content/${productCode}`});
    },

    handleCompanyClick(index) {
      // 여기에서 이미지를 클릭했을 때 처리할 로직을 작성합니다.
      console.log('Clicked on image:', this.companyList[index].englishName);
      this.$router.push({ path: `/company/${this.companyList[index].englishName}` });
    },
    async loadPage() {
      
      var serverUpdateDate = await storeService.getUpdateDate();
      var localUpdateDate =  localStorage.getItem("updateDate");
      console.log("update server : " +  serverUpdateDate + " localUpdateDate :" + localUpdateDate);

      if(localUpdateDate == null || serverUpdateDate > localUpdateDate){
        this.getServerDatas()
      }
      else{
        const sessionData = sessionStorage.getItem('homeData');

        if (sessionData) {
          const { moviesList, companyList, productList, productReleaseList, productPlaReleaseList, productPlaNotList, productStealReleaseList, productStealNotList, featuredCover } = JSON.parse(sessionData);
          this.moviesList = moviesList;
          this.companyList = companyList;
          this.productList = productList;
          this.productReleaseList = productReleaseList;
          this.productPlaReleaseList = productPlaReleaseList;
          this.productPlaNotList = productPlaNotList;
          this.productStealReleaseList = productStealReleaseList;
          this.productStealNotList = productStealNotList;
          this.featuredCover = featuredCover;
        }
        else{
          this.getServerDatas();
        } 
      // this.generateMetaInfo();
      }
    },
    async getServerDatas(){
      console.log("getServerDatas");
      try {
        // this.moviesList = await storeService.getHomeList();        
        this.companyList = await storeService.getCompanyDatas(); 

        this.companyList.sort((a, b) => {
          if (this.language === "ko") {
            return a.name.localeCompare(b.name);
          } else {
            return a.englishName.localeCompare(b.englishName);
          }
        });

        this.productList = await storeService.getProductDatas();
        this.productList.sort(function(a,b){
          return b.updateDate- a.updateDate
        });

        localStorage.setItem("updateDate", this.productList[0].updateDate);

        this.productPlaReleaseList = this.productList.filter((element) => { return element.productType == "plastic" && element.releaseType == "release"});
        this.productStealReleaseList = this.productList.filter((element) => {return element.productType == 'steel' && element.releaseType == 'release'});
        this.productPlaNotList = this.productList.filter((element) => {return element.productType == 'plastic' && element.releaseType == 'not'});
        this.productStealNotList = this.productList.filter((element) => {return element.productType == 'steel' && element.releaseType == 'not'});
        this.productReleaseList = this.productList.filter((element) => {return element.releaseType == 'release'});
         
        let featureData = this.productReleaseList[Math.ceil(Math.random()*this.productReleaseList.length) - 1];

        featureData.companyImg = this.companyList.find((element) => element.id == featureData.companyId).imgUrl;
        featureData.language = this.language;
        this.featuredCover = featureData;

        // 세션 스토리지에 데이터 저장
        const homeData = {
          moviesList: this.moviesList,
          companyList: this.companyList,
          productList: this.productList,
          productReleaseList: this.productReleaseList,
          productPlaReleaseList: this.productPlaReleaseList,
          productPlaNotList: this.productPlaNotList,
          productStealReleaseList: this.productStealReleaseList,
          productStealNotList: this.productStealNotList,
          featuredCover: this.featuredCover
        };
        sessionStorage.setItem('homeData', JSON.stringify(homeData));
      } catch (error) {
        console.error("Error loading page:", error);
      }
    },
    scrollListener() {
      this.scrolled = window.scrollY > 10;
    },
    movieImage(movie) {
      return `${movie.imgUrl}`;
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollListener);
  }
}

</script>

<style lang="scss">
#home {
  .lists {
    margin-top: 1%;
  }
  .big-menu {
    font-size: 30px;
    font-style: bold;
    margin-bottom: 30px;
    text-align: center;
    color: white;
    background-color: #121212;
  }

  .small-menu {
    background-color: #121212;
    font-size: 25px;
    margin: 20px 0px 0px 3%; /* 모바일에서 왼쪽 마진을 화면 너비의 5%로 설정합니다. */
    color: white;
    z-index: 3;
  }

  .grids {
    align-content: center;
    text-align: center;
    align-items: center;
  }

  .image-grid {
    align-content: center;
    text-align: center;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin: 3%; /* 좌우 마진을 화면 너비의 5%로 설정합니다. */
  }

  .image-item {
    position: relative;
  }

  .image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

.image-container {
  position: relative;
  width: 218px;
    height: 236px;
}

.image-desc {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height : 45px;
  background-color: rgba(0, 0, 0, 0.7); /* 타이틀 배경색 */
  padding-top: 10px;
}

.image-title {
  color: #fff; /* 타이틀 텍스트 색상 */
  text-align: center;
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
}

.image-subtitle {
  color: #fff; /* 타이틀 텍스트 색상 */
  text-align: center;
  font-size: 14px;
  margin: 0;
}

.circle {
  position: absolute;
  width: 10px; /* 조절 가능한 원의 지름 */
  height: 10px; /* 조절 가능한 원의 지름 */
  border-radius: 50%; /* 원 모양으로 만듭니다. */
  background-color: rgb(255, 0, 0); /* 빨간색 배경을 가집니다. */
  bottom: 10%; /* 이미지의 중앙에 위치시킵니다. */
  right: 3%; /* 이미지의 중앙에 위치시킵니다. */
}
@media (max-width: 480px) {

 

  .small-menu {
    font-size: 20px;
    margin: 20px 0px 15px 3%; /* 모바일에서 왼쪽 마진을 화면 너비의 5%로 설정합니다. */
  }
}
}

</style>

