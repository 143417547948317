import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/company/:englishName' ,
    name: 'company',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CompanyDetailView.vue'),
    beforeEnter: async (to, from, next) => {
      
      // 1. params의 postId를 확인한다.
      const postId = to.params?.englishName
     
      // 2. postId의 값이 없을 경우, Home으로 이동한다.
      if (!postId) {
        next({name: 'home'})    
      }

      window.scrollTo(0, 0);
      

      // 3. postId의 값이 있을 경우, PostComponent에 진입한다.
      next()
    },
    beforeRouteLeave(to, from, next) {
      // 뒤로가기 시 새로고침 방지
      next(false);
    }
  },
  {
    path: '/content/:productCode' ,
    name: 'content',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentView.vue'),
    beforeEnter: async (to, from, next) => {
      
      // 1. params의 postId를 확인한다.
      const postId = to.params?.productCode
     console.log("postId " + postId);
      // 2. postId의 값이 없을 경우, Home으로 이동한다.
      if (!postId) {
        next({name: 'home'})    
      }

      window.scrollTo(0, 0);
      

      // 3. postId의 값이 있을 경우, PostComponent에 진입한다.
      next()
    },
    beforeRouteLeave(to, from, next) {
      // 뒤로가기 시 새로고침 방지
      next(false);
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
