<template>
  <div class="loading">
    <img
      src="https://cdn.lowgif.com/full/0534e2a412eeb281-the-counterintuitive-tech-behind-netflix-s-worldwide.gif"
    >
  </div>
</template>


<style lang="scss">
.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 45%;
  }
}
</style>
