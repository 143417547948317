<template>
  <div>
    <section
      class="featured"
      :style="{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${item.imgUrl})`,
      }"  >
      
      <div class="featured--horizontal-transparency">
        <div class="featured--vertical-transparency">
          <div class="featured--title">{{getProductTitle}}</div>
          <div class="featured--sub-title">{{item.productCode}}</div>
          <div class="featured--info">
             {{ $t(type)  }}  {{ item.scale }}
          </div>
           <div class="featured--description">{{getDescription}}</div> 
          <div class="featured--buttons">
            <span class="featured--infobtn" @click="handleProductClick(item.productCode)">
              {{ $t('detalis_btn') }}
            </span> 
          </div>
          <!-- <div id="companyLogo" class="featured--icon">
                
            <img :src='` ${item.companyImg}`' >
            
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  props: {
    item: null
  },
  computed: {
    type() {
      if(this.item.productType == "plastic"){
        return "plamodel"
      }
      else{
        return "alloy_action_figure"
      }
    },
    getDescription() {

      var desc = this.item.companyName;

      if(this.$i18n.locale == "ko"){
        desc = this.item.companyName;
        if (this.item.seriesName.length > 0) {
          desc = desc + " > " + this.item.seriesName
        }
      }
      else{

        desc = this.item.englishCompanyName;
        if (this.item.englishSeriesName.length > 0) {
          desc = desc + " > " + this.item.englishSeriesName
        }

      }

      return desc;     
    },
    getProductTitle() {

      var title = this.item.name;
      if(this.$i18n.locale == "ko"){
        title = this.item.name;
        
      }
      else{
        title = this.item.englishName;
      }

      return title; 
    },
    votes() {
      return (this.item.vote_average * 10 * 100) / 100;
    },
    genres() {
      let genres = [];
      for (let i in this.item.genres) {
        genres.push(this.item.genres[i].name);
      }
      return genres.join(", ");
    },
    seasonsLabel() {
      return `Season${this.item.number_of_seasons !== 1 ? "s" : ""}`;
    },

   

  },
  methods: {
    handleProductClick(productCode) {
      // 여기에서 이미지를 클릭했을 때 처리할 로직을 작성합니다.
      this.$router.push({ path: `/content/${productCode}`});
    },
  }
};
</script>

<style lang="scss">
.featured {
  height: 80vh;
}

.featured--horizontal-transparency {
  position: relative; 
  width: inherit;
  height: inherit;
  background: linear-gradient(to top, #111 20%, transparent 80%);
}

.featured--vertical-transparency {
  width: inherit;
  height: 80%;
  background: linear-gradient(to right, #111 10%, transparent 70%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 60px;
  padding-top: 100px;
}

.featured--title {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
}

.featured--sub-title {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}

.featured--info {
  display : flex;
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
  color: #b0b0b0;
}

.featured--votes,
.featured--year,
.featured--seasons {
  display: inline-block;
  margin-right: 15px;
}

.featured--votes {
  color: #46d369;
  font-size: 16px;
}

.featured--description {
  margin-top: 15px;
  font-size: 22px;
  font-weight: 300;
  font-weight: bold;
  color: #b0b0b0;
  max-width: 40%;
}

.featured--buttons {
  margin-top : 30px;
  display: flex;
  justify-content: space-between;
}

.featured--buttons img{
   height: 50px;
   margin-right : 50px;
}

.featured--watch,
.featured--infobtn {
  display: inline-block;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  padding: 12px 25px;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 10px;
  opacity: 1;
  transition: all ease 0.2s;
}

.featured--watch:hover,
.featured--infobtn:hover {
  opacity: 0.7;
}

.featured--watch {
  background-color: #fff;
  color: #000;
}

.featured--infobtn {
  background-color: #333;
  color: #fff;
}

.featured--genres {
  margin-top: 15px;
  font-size: 15px;
  color: #999;
}

.featured--icon {
  position: absolute;
  right: 7%; /* 오른쪽 여백을 5%로 설정합니다. */
  top:66%;
}

.featured--icon img {
    height: 60px;
    right: 5%;
    bottom: 25%;
}

@media (max-width: 760px) {
  .featured {
    height: 80vh;
  }
  .featured--title {
    font-size: 40px;
  }
  .featured--info {
    font-size: 16px;
  }
  .featured--description {
    font-size: 14px;
    max-width: 100%;
    margin-right: 30px;
  }

  .featured--watch,
  .featured--infobtn {
    font-size: 16px;
  }
}

@media (min-width: 760px) {
  /* 웹 환경에서는 왼쪽 마진을 화면 너비의 3%로 설정합니다. */
  .featured--vertical-transparency {
    padding-left: 5%;
  }
}

@media (max-width: 480px) {
  /* 모바일 환경에서는 왼쪽 마진을 화면 너비의 5%로 설정합니다. */
  .featured--vertical-transparency {
    padding-left: 3%;
  }

  
.featured--title {
  font-size: 34px;
  font-weight: bold;
  color: #fff;
}

.featured--sub-title {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
}


  
}
</style>

